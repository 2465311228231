@import "mixins";
@import "colours";

.id_introHolder {
	
	@include box-sizing();
	padding: 48px 16px 16px 16px;
	width: 70%;
	margin: 0 auto;
	border-bottom: 1px solid $sectionBorderColour;

	.id_introPHolder {
		@include box-sizing();
		width: 100%;

		.id_techLogosHolder {
			margin: 32px auto 16px auto;

			.id_techLogoHolder {
				display: inline-block;
				vertical-align: top;
				width: 20%;
				@include box-sizing();
				padding: 0 8px;
				text-align: center;

				h3 {
					font-size: 0.888em;
					color: #999;
					text-align: center;
					margin: 8px 0 16px 0;
				}

				.id_techLogo {
					width: auto;
					height: 50px;
					& + .id_techLogo {
						margin-left: 4px;
					}
				}
			}
		}

		a {
			color: #333;
			background-color: #fff;
			border: 1px solid #cdcdcd;
			border-radius: 6px;
			@include box-sizing();
			padding: 2px 6px 2px 24px;
			background-image: url('../images/linkIcon.svg');
			background-repeat: no-repeat;
			@include bg-color-transition();

			&:hover {
				background-color: #ddd;
			}
		}

	}
}

/*
 * Responsive behaviour
*/
@media all and (max-width: 800px) {
	
	.id_introHolder {
		width: 80%;
	}
}

@media all and (max-width: 550px) {
	
	.id_introHolder {
		width: 90%;

		.id_introPHolder {

			.id_techLogosHolder {

				.id_techLogoHolder {
					width: 25%;
				}

			}
		}
	}
}

@media all and (max-width: 440px) {
	
	.id_introHolder {
		padding: 32px 16px 16px 16px;

		.id_introPHolder {

			.id_techLogosHolder {

				.id_techLogoHolder {
					width: 33.333%;
				}

			}
		}
	}
}